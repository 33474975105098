import React, { useState, useEffect } from 'react';
import upGreenRight from '../../srcImages/UpGreenRight.png'
import downRedRight from '../../srcImages/DownRedRight.png'
import BasicTickerEvaluaton from '../../Components/BasicTickerEvaluaton/BasicTickerEvaluaton'

const CURRENT_HOLDINGS = 
  [
    {
      ticker: "DIA",
      costBasis: '448.9',
      unitsOnHand: 10,
      calculateAccumulatedProfitLoss: true,             
    },
    {
      ticker: "VOO",
      costBasis: '435.70',
      unitsOnHand: 44, 
      calculateAccumulatedProfitLoss: true,         
    },
    {
      ticker: "QQQ",
      costBasis: '466.40',
      unitsOnHand: 28,  
      calculateAccumulatedProfitLoss: true,               
    },    
    {
      ticker: "AAPL",
      costBasis: '181.22',
      unitsOnHand: 54,
      calculateAccumulatedProfitLoss: true,         
    },    
    {
      ticker: "ABT",
      costBasis: '116.08',
      unitsOnHand: 23,
      calculateAccumulatedProfitLoss: true,         
    },
    {
      ticker: "AMZN",
      costBasis: '145.91',
      unitsOnHand: 155,
      calculateAccumulatedProfitLoss: true,        
    },
    {
      ticker: "AVGO",
      costBasis: '157.71',
      unitsOnHand: 113,
      calculateAccumulatedProfitLoss: true,       
    },
    {
      ticker: "BLK",
      costBasis: '948.94',
      unitsOnHand: 3,
      calculateAccumulatedProfitLoss: true,
    },
    {
      ticker: "BMY",
      costBasis: '56.57',
      unitsOnHand: 175,
      calculateAccumulatedProfitLoss: true,
    },
    {
      ticker: "BRK-B",
      costBasis: '403.59',
      unitsOnHand: 10,
      calculateAccumulatedProfitLoss: true,          
    },
    {
      ticker: "COF",
      costBasis: '102.86',
      unitsOnHand: 320,
      calculateAccumulatedProfitLoss: false,
    },
    {
      ticker: "COST",
      costBasis: '953.03',
      unitsOnHand: 6,
      calculateAccumulatedProfitLoss: true,          
    },
    {
      ticker: "CRM",
      costBasis: '336.26',
      unitsOnHand: 21,
      calculateAccumulatedProfitLoss: true,
    },
    {
      ticker: "CRWD",
      costBasis: '335.22',
      unitsOnHand: 40,
      calculateAccumulatedProfitLoss: true,         
    },
    {
      ticker: "ENB",
      costBasis: '35.56', 
      unitsOnHand: 70,
      calculateAccumulatedProfitLoss: true,         
    },
    {
      ticker: "ETN",
      costBasis: '287.21',
      unitsOnHand: 12,
      calculateAccumulatedProfitLoss: true,         
    },
    {
      ticker: "GS",
      costBasis: '568.70',
      unitsOnHand: 10,
      calculateAccumulatedProfitLoss: true,
    },
    {
      ticker: "LLY",
      costBasis: '780.52',
      unitsOnHand: 5,
      calculateAccumulatedProfitLoss: true,
    },
    {
      ticker: "MDB",
      costBasis: '310.99',
      unitsOnHand: 5,
      calculateAccumulatedProfitLoss: true,
    },
    {
      ticker: "META",
      costBasis: '350.73',
      unitsOnHand: 40,
      calculateAccumulatedProfitLoss: true,    
    },
    {
      ticker: "MRVL",
      costBasis: '110.45',
      unitsOnHand: 30,
      calculateAccumulatedProfitLoss: true,
    },
    {
      ticker: "MSFT",
      costBasis: '313.95',
      unitsOnHand: 40,
      calculateAccumulatedProfitLoss: true,      
    },
    {
      ticker: "NLY",
      costBasis: '19.986',
      unitsOnHand: 300,
      calculateAccumulatedProfitLoss: true,      
    },
    {
      ticker: "NVDA",
      costBasis: '59.28',
      unitsOnHand: 495,
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "NVO",
      costBasis: '85.99',
      unitsOnHand: 18,
      calculateAccumulatedProfitLoss: true,
    },
    {
      ticker: "O",
      costBasis: '56.29',
      unitsOnHand: 160,
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "ORCL",
      costBasis: '169.53',
      unitsOnHand: 10,
      calculateAccumulatedProfitLoss: true,
    },
    {
      ticker: "PDI",
      costBasis: '19.41',
      unitsOnHand: 270,
      calculateAccumulatedProfitLoss: true,
    },
    {
      ticker: "WFC",
      costBasis: '51.88',
      unitsOnHand: 100,
      calculateAccumulatedProfitLoss: true,      
    },
    {
      ticker: "WMT",
      costBasis: '91.39',
      unitsOnHand: 30,
      calculateAccumulatedProfitLoss: true,
    }

  ];


function CurrentHoldings() {

  const [stockSymbolToFetch,setStockSymbolToFetch] = useState('AAPL')
  const [headerValue,setHeaderValue] = useState('Current ')
  const [todaysPercentageChange, setTodaysPercentageChange] = useState(0.0);
  const [isTodaysChangePositive, setIsTodaysChangePositive] = useState(true);
  const [slope, setSlope] = useState(0.0)
  const [currentHoldings,setCurrentHoldings]=useState(CURRENT_HOLDINGS);

  const onSelectTickerButtonHandler=(tickerToEvaluate)=>
  {
    setStockSymbolToFetch(tickerToEvaluate)
    //console.log("Setting stockSymbolToFetch: " +stockSymbolToFetch)
  }

  const onSetHeader=(headerValueIn)=>
  {
    setHeaderValue(headerValueIn)
  }

  const onSetTodaysPercentageChange = (percentageChange, isChnagePositive) => {
    setTodaysPercentageChange(percentageChange);
    setIsTodaysChangePositive(isChnagePositive);
  }

  const onSetSlope = (slopeIn) => {
    setSlope(slopeIn)
  }

  useEffect(() => {
    document.title = "Current Holdings"
 }, []);


  useEffect(() => {  
    //console.log("Running useEffect for: stockSymbolToFetch: " +stockSymbolToFetch)
}, [stockSymbolToFetch,headerValue,slope]);

  return (
    <div className="text-center">      
      
    <header className="bg-indigo-100 text-purple-600 text-3xl font-bold h-18 justify-items-center">
      <div>
        {headerValue}
      </div>   
      <div>
                {isTodaysChangePositive === true ?
                    <div className='text-green-600 text-3xl font-bold'>
                        Today's Change: {todaysPercentageChange} %
                    </div> :
                    <div className='text-red-600 text-3xl font-bold'>
                        Today's Change: {todaysPercentageChange} %
                    </div>
                    }
      </div>     
      <div>
        {slope >= 0.0 ?
          <div className='text-green-600 text-3xl font-bold'>            
                {/*Exponential change: {slope}  */}              
                <img className="inline-block w-10 h-8 ml-7 " src={upGreenRight} alt=""></img>                           
          </div> :
          <div className='text-red-600 text-3xl font-bold'>
                {/*} Exponential change: {slope} */} 
                  <img className="inline-block w-12 h-10 ml-7" src={downRedRight} alt=""></img> 
          </div>
          }
      </div>          
    </header>

    <BasicTickerEvaluaton onSelectTickerButtonHandler = {onSelectTickerButtonHandler} onSetHeader = {onSetHeader} baseHeader='Current'
     onSetTodaysPercentageChange={onSetTodaysPercentageChange}
                          onSetSlope = {onSetSlope} tickerEntries={currentHoldings} backgroundLeft='bg-indigo-100'
                          buttonBackgroundColor='bg-indigo-400'/>
    {/*<StockQuote stockSymbol={stockSymbolToFetch}/>*/}
    </div>
  );
}

export default CurrentHoldings;
